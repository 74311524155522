import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './style.css'
const ThumbSlider = ({rows, cols, compArr, innerW}) =>{
    const calcBumperWidth = (innerWidth) =>{
        if(innerWidth > 850){
            return 50
        }
        return 35
    }
    const [bumperWidth, setBumperWidth] = useState(50)

    const [page, setPage] = useState(0)
    const pageDisplayArr = []
    const pageSelectors = []
    const pageSize = rows * cols
    const pages = Math.ceil(compArr.length/pageSize)
    useEffect(()=>{
        setPage(0)
    },[cols])
    useEffect(()=>{
        setBumperWidth(calcBumperWidth(innerW))
    },[innerW])
    const incPage = () =>{
        if(page + 1 == pages){
            setPage(0)
        }else{
            setPage(page + 1)
        }
    }
    const decPage = () =>{
        if(page == 0){
            setPage(pages - 1)
        }else{
            setPage(page - 1)
        }
    }
    for (let pageInd = 0; pageInd < pages; pageInd++){
        let page_disp = []
        for(let row = 0; row < rows; row++){
            let row_disp = []
            if((pageSize * pageInd) + (row * cols) < compArr.length){
                for(let col = 0; col < cols; col++){
                    let transform_index = (pageSize * pageInd) + (row * cols) + col
                    //console.log(`transform: ${transform_index}`,{pageSize, pageInd, row, rows, col})
                    row_disp.push(compArr[transform_index] || (<div className='thumb-empty'></div>))
                }
                page_disp.push(row_disp)
            }
        }
        pageDisplayArr.push(page_disp)
        pageSelectors.push(
            <div 
                className={`page-selector-button ${pageInd === page? 'page-active' : ''}`}
                onClick={()=>{setPage(pageInd)}}
            ></div>
        )
    }
    const pageDisplay = pageDisplayArr.map((pageInd)=>{

        return(
            <div className={`slider-page`}>
                {pageInd.map((row) =>{
                    return(
                        <div className={`slider-row`}>
                            {row.map((item) =>{
                                return(
                                    <div className={`slider-item`} style={{width:`calc(${100/cols}% - 15px)`, height:'fit-content'}}>
                                        <div style={{paddingTop: '', position: 'relative'}}>
                                            {item}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    })
    return(
        <div className={`slider-container`}>
            <div className={`page-window`} style={{width: `calc(100% - ${2 * bumperWidth}px)`}}>
                <div className={`page-container`} style={{transform:`translateX(-${100 * page}%)`}}>
                    {pageDisplay}
                </div>
            </div>
            <div className={'bumper bumper-left'} style={{width: `${bumperWidth}px`}} onClick={()=>{decPage()}}>
                <div className='bumper-arrow arrow-left FA-icon'>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </div>
            </div>
            <div className={'bumper bumper-right'} style={{width: `${bumperWidth}px`}} onClick={()=>{incPage()}}>
                <div className='bumper-arrow arrow-right FA-icon'>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </div>
            </div>
            <div className={'page-select'}>
                {pageSelectors}
            </div>
        </div>
    )
}

export default ThumbSlider