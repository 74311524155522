import React from 'react'
import Icon from '../../assets/CB_logo_basic.js'
import Breaking_Down from '../../assets/Breaking_Down.svg'
const Blurb = ({children, odd, color_1, color_2, ref}) => {
    return(
        <div 
            className={`homepage-blurb-container ${odd? 'odd' : ''}`}
            style={{backgroundColor: color_1}}
            ref={ref}    
        >
            {/* <div className='blurb-background' style={{stroke: color_2, fill:color_2, stroke:'#191919', fill: '#191919', opacity:'.25'}}><Icon /></div> */}
            <div className='blurb-background' style={{backgroundImage:`url(${Breaking_Down})`}}></div>
            {children}
        </div>
    )
}

export default Blurb