import React, {useState, useEffect, createContext} from 'react'
import './App.css';
import Homepage from './pages/Homepage'
import Navbar from './pages/Navbar'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom"
import Blog from './pages/Blog';
export const blogDataContext = createContext()

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [blogData, setBlogData] = useState([])
  const [recipesData, setRecipesData] = useState([])
  const [scrollH, setScrollH] = useState(0)
  useEffect(()=>{
      const onScroll = () =>{
          setScrollH(window.pageYOffset)
      }
      window.addEventListener('scroll', onScroll)
      onScroll()
      return () => window.removeEventListener('scroll', onScroll)
  },[])

  return (
    <blogDataContext.Provider value={{blog:[blogData, setBlogData], recipes:[recipesData, setRecipesData]}}>
      <div id='main'>
        <Router>
          <Navbar
            setIsMenuOpen={setIsMenuOpen}
            isMenuOpen={isMenuOpen} 
            scrollH = {scrollH}

          />
          <Routes>
            <Route index path='/' element={
              <Homepage
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                blogData={blogData}
                setBlogData={setBlogData}
                recipesData={recipesData}
                setRecipesData={setRecipesData}
                scrollH={scrollH}
              />
            }/>
            <Route path='/blog/:id' element={<Blog btype='blog' />}/>
            <Route path='/recipe/:id' element={<Blog btype='recipe'/>}/>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>

    </blogDataContext.Provider>
  );
}

export default App;
