import React, { useEffect, useState, useContext } from 'react'
import { blogDataContext } from '../../App'
import { useParams } from 'react-router-dom'
import { fetchAllBlogs } from '../../helpers'
import LoadingGif from '../../assets/LoadingGifV2.gif'
import './style.css'
const Blog = ({btype}) =>{
    const params = useParams()
    const [isBlogLoading, setIsBlogLoading] = useState(false)
    const {blog, recipes} = useContext(blogDataContext)
    const [blogData, setBlogData] = blog
    const [recipesData, setRecipesData] = recipes

    const {id} = params
    let blogObj = {}
    switch (btype) {
        case 'blog':
            blogObj = blogData.find((blog)=>blog.id === id)
            break
        case 'recipe':
            blogObj = recipesData.find((blog)=>blog.id === id)
            break

    }
    const blogHTML = blogObj?.content || '<div>No Blog Found</div>'
    const title = blogObj?.title


    useEffect(()=>{
        const fetchBlogs = async () =>{
            try{
                setIsBlogLoading(true)
                const {blogData, recipesData} = await fetchAllBlogs()
                setBlogData(blogData)
                setRecipesData(recipesData)
                setIsBlogLoading(false)
                
            }catch(err){
                setIsBlogLoading(false)
                console.log('unable to fetch blogs')
            }
        }
        if(!blogData.length || !recipesData.length){
            fetchBlogs()
        }
    },[])
    useEffect(() =>{
        window.scrollTo(0,0);
    },[])
    return(
        <div style={{overflowX: 'scroll'}}>
            <div className='blog-container'>
                {isBlogLoading?
                <div id='loading-frame' style={{
                    backgroundImage: `url(${LoadingGif})`,
                    width: '100%',
                    height: '400px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}></div>
                :
                <>
                    <div className='post-title'>
                        <h3>{title}</h3>
                    </div>
                    <div className='post-body entry-content float-container'>
                        <div dangerouslySetInnerHTML={{ __html: blogHTML }}></div>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default Blog