import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faInstagram, faPatreon, faPaypal, faTwitter } from "@fortawesome/free-brands-svg-icons"
import './index.css'
import { SingleLink } from './SingleLink'
const socialLinks = ['youtube', 'twitter', 'instagram', 'patreon', 'paypal']

const SocialLinks = ({}) =>{

    return socialLinks.map(socialLink => <SingleLink social={socialLink}/>)

}

export default SocialLinks