import React, {useState, useEffect} from 'react'
import './style.css'
import logo_1 from '../../assets/cb_logo_1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Breaking_Down from '../../assets/Breaking_Down.svg'
import CB_logo from '../../assets/CB_logo.svg'
import WebsiteProfilePic from '../../assets/WebsiteProfilePic.png'
import WebsiteMainPhotoBW from '../../assets/WebsiteMainPhotoBW.svg'
import Sun from '../../assets/Sun.svg'
import Sun_mobile from '../../assets/Sun_mobile.svg'
import SocialLinks from '../../components/SocialLinks'
const Navbar = ({isMenuOpen, setIsMenuOpen, scrollH}) =>{
    const nav = useNavigate();
    const tabs = [
        {
            id: 'home',
            label: 'Home'
        },
        {
            id: 'about',
            label: 'About'
        },
        {
            id: 'video',
            label: 'Videos'
        },
        {
            id: 'recipe',
            label: 'Recipes'
        },
        {
            id: 'blog',
            label: 'Blog'
        },
        {
            id: 'contact',
            label: 'Contact'
        }

    ]
    const handleNavClick = () =>{
        setIsMenuOpen(false)
    }
    const toggleIsMenuOpen = () => {setIsMenuOpen(!isMenuOpen)}
    const tabDisplay= tabs.map((tab) =>{
        return(
            <div className='tab-container' key={tab.id}>
                <HashLink smooth to={`/#${tab.id}`} onClick={()=> handleNavClick() }>
                    <div className='tab-content'>{tab.label}</div>
                </HashLink>
            </div>
        )
    })
    return(
        <>
            {scrollH > 150 &&
                <div className='nav-links-container nav-links-sticky'>
                    {tabDisplay}
                </div>}
            <div 
                id='nav-container'
                className={`${isMenuOpen? `menu-open`: ``} `}    
            >
                <div 
                    id='nav-container-background'
                    style={{
                        backgroundImage:`url(${Sun})`,
                        position: 'absolute',
                        width:'1000px',
                        height:'150px',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'cover',
                        backgroundPosition:'left center',
                        height: '100%',
                        left: '200px',
                        backgroundPositionY: '47%',
                        width: '1713px',
                        opacity: '.5',
                    }}
                ></div>
                <div 
                    id='mobile-nav-container-background'
                    style={{
                        backgroundImage:`url(${Sun_mobile})`,
                        backgroundPosition: 'center',
                        position: 'absolute',
                        width: '100vw',
                        height: '200%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        opacity: '0.5',
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                ></div>         
                {/* <div 
                    id='breaking-logo'
                    style={{backgroundImage: `url(${Breaking_Down})`}}
                >

                </div> */}
                <div id='nav-logo-container'>
                    <HashLink className='hash-link' smooth to={`/#home`} onClick={()=> handleNavClick() }>
                        <div id='nav-logo-main' style={{backgroundImage:`url(${WebsiteMainPhotoBW})`}}></div>
                    </HashLink>
                    <div id='nav-logo-text'>
                    <div 
                        id='breaking-logo-on-text'
                        style={{backgroundImage: `url(${Breaking_Down})`}}
                    >
                    </div>
                    <HashLink className='hash-link' smooth to={`/#home`} onClick={()=> handleNavClick() }>
                        <span id='logo-name'>Chris Bonham</span>
                    </HashLink>
                    </div>
                </div>
                <div id='nav-right-container'>
                    <div id='social-container'>
                        <SocialLinks />
                    </div>
                    <div className='nav-links-container'>
                        {tabDisplay}
                    </div>
                </div>
            </div>
            <div id='nav-hamburger-button'>
                    <div 
                        className='FA-icon' id='hamburger-icon' 
                        onClick={()=>{toggleIsMenuOpen()}}
                        style={{transition:'.3s', transform: `rotate(${isMenuOpen? 90: 0}deg)`}}
                        >
                        <FontAwesomeIcon icon={faBars}/>
                    </div>
            </div>
            <div className={`mobile-tab-menu ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className='mobile-nav-links-wrapper'>
                    <div id='mobile-nav-links'>
                        {tabDisplay}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Navbar