import React, { useEffect, useState } from 'react'
import Hexagon from '../../components/Hexagon'
import './charity-tree.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Icon from '../../assets/CB_logo_basic'
import FeatherIcon from '../../assets/CB_feather'
import KA_logo from '../../assets/KA_logo'
import BranchesSVG from '../../assets/Charity_Branches.svg'
import CB_logo from '../../assets/CB_logo.svg'
import Heart_Hex from '../../assets/Heart_Hex.svg'
import Tree_Hex from '../../assets/Tree_Hex.svg'
import Book_Hex from '../../assets/Book_Hex.svg'
import EDF_Hex from '../../assets/EDF_Hex.svg'
import EA_Hex from '../../assets/EA_Hex.svg'
import KA_Hex from '../../assets/KA_Hex.svg'
import Portrait_Hex from '../../assets/Portrait_Hex.svg'

//import {ReactComponent as JS_logo} from '../../assets/JS_logo.jpg'
const hexText = {
    CB_logo_top: <div><p>Chris' Logo</p><p>When you see this logo at the beginning of a project it signifies that any and all proceeds will
    follow the below donation plan. The logo design is in the spirit of no one being truly responsible
    for their position in the world. Even the successes are all dictated by tangible things outside of
    our control whether they be biological or systemic. If you can buy that idea, it follows to use
    whatever is fortunate about your station in life to fill in the remaining gaps in the greater tangible
    structure which holds everyone. If you can find a more sanctimonious and dramatic logo
    description than this one, contact us and we will refund you.</p></div>,
    CB_logo: <div><p>Chris </p><p>50% percent of proceeds go to Chris in support of his future projects, his ma's impending
    vacation to Vietnam, and his overindulgent lifestyle of fast cars and - the joke used to follow that
    one might say “loose” here but my intuition says that that part is no longer as funny to as many
    - women.</p></div>,
    heart:<div><p>Charitable Organizations</p><p>50% of proceeds will be distributed equally between the below organizations. These
    organizations will always be subject to change upon new information. But at the moment I make
    the SVGs for this website so it would really take some convincing new info for me to do that.
    Changes will be announced via Twitter but let's all hope for my sake that nothing effects the
    quality or public perception of any of the below chariti- *Sam Bankman-Fried has been arrested*</p></div>,
    tree:<div><p>Environmental Organizations </p><p>Environmental health is one of two focus areas of donation. The environment is in many ways
    the fundamental cause. The sooner we fix it, the sooner Greta Thunberg can return to her
    normal Swedish childhood of Legos, pickled herring and Köttbullar. If you are finding it ironic
    that such a seemingly meat centric person is supporting the environment, please see: “If You
    are Finding it Ironic” in the Blog section of this website.</p></div>,
    edf:<div><p>Environmental Defense Fund</p><p>The Environmental Defense Fund addresses climate change from an angle which often goes
    unmentioned: the fiscal-legal viability of protecting the planet. While I'm not here to yuck the
    yum of any other approaches to helping the environment, EDF seems to be foundational to all of
    them in one way or another. <a target='_blank' href="https://www.edf.org/our-mission-and-values#:~:text=Environmental%20Defense%20Fund%27s%20mission%20is,the%20most%20serious%20environmental%20problems.">EDF Mission Statement</a></p></div>,
    book:<div><p>Educational Organizations</p><p>Education is one of our two focus areas of donation. While there are many worthwhile causes to
    support, raising the bar for education indirectly raises the bar for all others. Generally, the more
    people understand something, the less angry they can be about it.</p></div>,
    ka:<div><p>Khan Academy</p><p>When I returned to my education at a community college, I observed the impact of having
    lessons available outside of the classroom for both myself, an idiot, and also for lower income
    students. Being able to pause, rewind and rewatch instruction is invaluable. For organizations
    like Khan Academy that produce and distribute the lessons effectively, it also raises the
    standard of instruction across the board, limiting variance arising from instructors, schools, or
    home-life situations. <a target='_blank' href="https://www.khanacademy.org/about#:~:text=Our%20mission%20is%20to%20provide,class%20education%20for%20anyone%2C%20anywhere.">KA Mission Statement</a></p></div>,
    ea:<div><p>Effective Altruism</p><p>Effective Altruism is an organization that vets and evaluates the most effective organizations to
    donate your money to on the basis of which ones accomplish the most good per dollar. I wanted
    to incorporate them in my donation plan although not strictly educational or environmental as
    they have a strong influence of global philosophy and psychology in their company which I think
    both connects the other two areas of donation while also making room for me to address other
    pre-approved areas of good. <a target='_blank' href='https://www.effectivealtruism.org/articles/introduction-to-effective-altruism'>EA Mission Statement</a></p></div>,

}
const CharityTree = ({margin, isMobile}) =>{
    const [dontatedAmt, setDonatedAmt] = useState(0)
    const [hexMessage, setHexMessage] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [selectedHex, setSelectedHex] = useState(0)
    return (
        <div className='charity-tree-wrapper'
            onMouseLeave={()=>{setHexMessage(false)}}
        >
            <div 
                id='charity-tree' 
                style={{
                    backgroundImage:`url(${BranchesSVG})`,
                    marginTop:`${margin}px`,
                }}
            >
                <h2 id='banner-title' className='header-font'>Donation Plan</h2>
                <SoloHex svg={CB_logo} text={hexText.CB_logo_top} h={115} x={49} y={35} ind={1} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={Portrait_Hex} text={hexText.CB_logo} h={85} x={0} y={214} ind={2} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={Heart_Hex} text={hexText.heart} h={85} x={118} y={270} ind={3} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={Tree_Hex} text={hexText.tree} h={85} x={13} y={457} ind={4} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={Book_Hex} text={hexText.book} h={85} x={146} y={458} ind={5} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={EDF_Hex} text={hexText.edf} h={71} x={0} y={636} ind={6} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={EA_Hex} text={hexText.ea} h={71} x={84} y={636} ind={7} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <SoloHex svg={KA_Hex} text={hexText.ka} h={71} x={170} y={636} ind={8} setSelectedHex={setSelectedHex} selectedHex={selectedHex}/>
                <h2 
                    id='amount-donated' 
                    className='header-font'
                    style={{
                        bottom: `${isMobile? 5 : 0 + margin}px`,
                        background: 'white',
                        zIndex: '2'
                    }}
                >Amount Donated: ${dontatedAmt}</h2>
            </div>
            <div className='hex-pop'
                style={hexMessage? {height: '165px'}: {height:'0px'}}
            >
                <div className='hex-pop-inner'>
                    <span>{hexMessage}</span>
                </div>
            </div>
        </div>
    )
}

export default CharityTree

const SoloHex = ({svg, h, x, y, ind, selectedHex, setSelectedHex, text}) =>{
    const [isSelected, setIsSelected] = useState(false)
    const [isAnimationDone, setIsAnimationDone] = useState(true)
    const [shouldHover, setShouldHover] = useState(true)
    useEffect(()=>{
        setIsSelected(selectedHex === ind)
        if(selectedHex === ind){
            console.log(`selected hex ${selectedHex}`)
            setIsAnimationDone(true)
            setShouldHover(false)
        }else{
            setTimeout(()=>{setIsAnimationDone(false); setShouldHover(true)}, 1000)
        }
    },[selectedHex])

    return(
        <div 
            className={`charity-svg-container ${isSelected?'selected':''}`}
            style={{
                width: `${isSelected? 245 : (h * 1.16)}px`,
                top: `${y}px`,
                left: `${x}px`,
                zIndex: `${isAnimationDone? 2 : 1}`
            }}
        >
            {isSelected &&
                <div className='close-svg FA-icon' onClick={()=>{setSelectedHex(0)}}>
                    <FontAwesomeIcon icon={faWindowClose} />
                </div>
            }
            <div className={`charity-svg-container-${shouldHover? 'grow' : 'nogrow'}`}>
                <div 
                    onClick={()=>{setSelectedHex(selectedHex? 0 : ind)}}
                    className={`charity-svg ${isSelected?'selected':''}`}
                    style={{
                        backgroundImage:`url(${svg})`,
                        width:'100%',
                        height: `${isSelected? 245/1.16 : h}px`,
                    }}
                >
                </div>
                <div className={`svg-drawer ${isSelected?'open':''}`}>
                    <div className='drawer-text'>
                        <p>
                            {text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
const TreeHex = ({children, id, message, setHexMessage = ()=>{}, setIsHover = ()=>{}}) =>{
    return(
        <div className='tree-hex'
            onClick={()=>{setHexMessage(message)}}
            onMouseEnter={()=>{setIsHover(id)}}
            onMouseLeave={()=>{setIsHover(false)}}
        >
            {children}
        </div>
    )
}
const TreeBranch = ({top, left, right}) =>{
    return(
        <div className='tree-branch'
            style={{}}
        >
            <div className='tree-branch-top'></div>
        </div>   
    )
}