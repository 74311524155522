const BLOGGER_BLOG_ID = '8890008770391658743'
const BLOGGER_RECIPES_ID = '2546561405732475576'
const GOOGLE_API_KEY = 'AIzaSyCmIe2U8dfJ7QciPRf1PVaSmZSlcXEP_pk'

export async function fetchAllBlogs(){
    const finalRes = {recipesData:[], blogData:[]}
    try{
      let url = new URL(`https://www.googleapis.com/blogger/v3/blogs/${BLOGGER_RECIPES_ID}/posts`)
      let params = {
          key: GOOGLE_API_KEY
      }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
      let res = await fetch(url,{
          method: 'GET',
      })
      let resJson = await res.json()
      if(resJson.items.length){
          finalRes.recipesData = resJson.items
      }

      url = new URL(`https://www.googleapis.com/blogger/v3/blogs/${BLOGGER_BLOG_ID}/posts`)
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
      res = await fetch(url,{
          method: 'GET',
      })
      resJson = await res.json()
      if(resJson.items.length){
          finalRes.blogData = resJson.items
      }
      return finalRes
    }catch(err){
        console.log('unable to fetch blogs')
    }
  }