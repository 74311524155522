import React, {useState, useEffect, useRef, useContext} from 'react'
import './style.css'
import Blurb from './Blurb'
import ThumbSlider from '../../components/ThumbSlider'
import YouTubePlayer from '../../components/YouTubePlayer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import CharityTree from './CharityTree'
import { useNavigate, useLocation } from 'react-router-dom'
import youtube_link_thumb from '../../assets/youtube_link_thumb.png'
import MobileCharityTree from './MobileCharityTree'
import SocialLinks from '../../components/SocialLinks'
import { SingleLink } from '../../components/SocialLinks/SingleLink'
import { fetchAllBlogs } from '../../helpers'
import { blogDataContext } from '../../App'
const GOOGLE_API_KEY = 'AIzaSyCmIe2U8dfJ7QciPRf1PVaSmZSlcXEP_pk'

const Homepage = ({isMenuOpen, setIsMenuOpen, scrollH, location}) =>{
    const loc = useLocation()
    const {hash} = loc
    const nav = useNavigate()
    const anchors = {
        home: useRef(null),
        about: useRef(null),
        video: useRef(null),
        recipe: useRef(null),
        blog: useRef(null),
        contact: useRef(null)
    }
    const {blog, recipes} = useContext(blogDataContext)
    const [blogData, setBlogData] = blog
    const [recipesData, setRecipesData] = recipes
    const [prevHeight, setPrevHeight] = useState(0)
    const calcVideoWidth = (windowWidth) =>{
        return ((windowWidth - bannerWidth) * .85)
    }
    const calcThumbDimensions = (windowWidth) =>{
        if(windowWidth > 1250){
            return {cols: 4, rows: 3}
        }if(windowWidth > 850){
            return {cols: 3, rows: 3}
        }
        return {cols: 2, rows: 2}
    }
    const scrollToAnchor = (tag) =>{
        if(tag === 'home'){
            window.scrollTo({top:0,left:0, behavior:'smooth'})
        }else if(anchors[tag]){
            console.log(anchors[tag])
            anchors[tag].current?.scrollIntoView({behavior: 'smooth'})
        }else{
            window.scrollTo(0,0)
        }
    }
    const charities = ['Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity','Test Charity',]
    const charitiesDisplay = charities.map((charity)=>{
        return(
            <div className='charity-container'>
                <h3 className='charity-title header-font'>{charity}</h3>
            </div>
        )
    })

    const [bannerWidth, setBannerWidth] = useState(250)
    const [thumbDimensions, setThumbDimensions] = useState(calcThumbDimensions(window.innerWidth))
    const [w, setW] = useState(window.innerWidth)
    const [videoW, setVideoW] = useState(calcVideoWidth(window.innerWidth))
    const [videoContent, setVideoContent] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isBlogLoading, setIsBlogLoading] = useState(false)
    const [isRecipesLoading, setIsRecipesLoading] = useState(false)
    const [bannerMargin, setBannerMargin] = useState(150)
    const maxHeight = 600
    let height = (videoW * .5625 < maxHeight)? '56.25%' : `${maxHeight}px`
    useEffect(()=>{
        if(scrollH < prevHeight){
            scrollH !== prevHeight && setPrevHeight(scrollH)
            let val = Math.max(0, 195-scrollH)
            if(scrollH < 149){
                val = Math.max(0, 150-scrollH)
            }
            setBannerMargin(val)
        }else{
            scrollH !== prevHeight && setPrevHeight(scrollH)
            setBannerMargin(Math.max(0, 150-scrollH))
        }
    },[scrollH])
    useEffect(()=>{
        const onResize = () =>{
            setW(window.innerWidth)
            setVideoW(calcVideoWidth(window.innerWidth))
            setThumbDimensions(calcThumbDimensions(window.innerWidth))
        }
        window.addEventListener('resize', onResize)
        onResize()
        return () => window.removeEventListener('resize', onResize)
    }, [])
    useEffect(()=>{
        const fetchYoutube = async () =>{
            try{
                setIsLoading(true)
                let url = new URL('https://www.googleapis.com/youtube/v3/playlistItems')
                let params = {
                    part: 'contentDetails,id,snippet,status',
                    playlistId: 'PL03o0OXW1-dar2wfMuNQS9mhNHOFKBslu',
                    maxResults: '30',
                    key: GOOGLE_API_KEY
                }
                Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
                let res = await fetch(url,{
                    method: 'GET',
                })
                let resJson = await res.json()
                console.log(resJson)
                if(resJson.items.length){
                    setVideoContent(resJson.items)
                }
                setIsLoading(false)
            }catch(err){
                setIsLoading(false)
                console.log('unable to fetch youtubes')
            }
        } 
        fetchYoutube()
    },[])
    useEffect(()=>{
        const fetchBlogs = async () =>{
            try{
                setIsBlogLoading(true)
                const {blogData, recipesData} = await fetchAllBlogs()
                setBlogData(blogData)
                setRecipesData(recipesData)
                setIsBlogLoading(false)
                
            }catch(err){
                setIsBlogLoading(false)
                console.log('unable to fetch blogs')
            }
        } 
        fetchBlogs()
    },[])
    useEffect(()=>{
        scrollToAnchor(hash.replace('#',''))
    },[loc])
    let videoThumbs = videoContent.map((item)=>{
        let {title, description, thumbnails, resourceId} = item.snippet
        let videoId = ''
        try{
            videoId = resourceId.videoId
        }catch(err){
            console.log(err)
        }
        let watchURL = `https://www.youtube.com/watch?v=${videoId}`
        description = description.substring(0, 14)
        return(
            <a target='_blank' href={watchURL} style={{textDecoration: 'none'}}>
                <div className='youtube-item'>
                    <div className='youtube-thumb' style={{backgroundImage: `url("${thumbnails.high.url}")`}}>
                        <div className='FA-icon youtube-icon'><FontAwesomeIcon icon={faYoutube}/></div>
                    </div>
                    <div className='youtube-details'>
                        <div className='youtube-title'>{title}</div>
                    </div>
                </div>
            </a>
        )
    })
    let blogThumbs = blogData.map((item)=>{
        let {title, author, id, content} = item
        let thumb = content.match(/(href|src)="[^"]*"/)
        thumb = thumb? thumb[0].split('"')[1] : null
        return(
            <a target='_blank' onClick={() => nav({pathname:`/blog/${id}`})} style={{textDecoration: 'none'}}>
                <div className='youtube-item'>
                    <div className='youtube-thumb' style={{backgroundImage: `url("${thumb}")`}}></div>
                    <div className='youtube-details'>
                        <div className='youtube-title'>{title}</div>
                    </div>
                </div>
            </a>
        )
    })
    let recipeThumbs = recipesData.map((item)=>{
        let {title, author, id, content} = item
        let thumb = content.match(/(href|src)="[^"]*"/)
        thumb = thumb? thumb[0].split('"')[1] : null
        return(
            <a target='_blank' onClick={() => nav({pathname:`/recipe/${id}`})} style={{textDecoration: 'none'}}>
                <div className='youtube-item'>
                    <div className='youtube-thumb' style={{backgroundImage: `url("${thumb}")`}}></div>
                    <div className='youtube-details'>
                        <div className='youtube-title'>{title}</div>
                    </div>
                </div>
            </a>
        )
    })
    let socialLinkMaker = (arr) =>{
        return arr.map(link => <SingleLink social={link} />)
    }
    return(
        <div id='homepage-container' className={`${isMenuOpen? 'menu-open' : ''}`}>
            <div id='homepage-banner'>
            </div>
            <div id='homepage-content' >
                <div id='content-main' style={{marginLeft: `${w > 850 ? bannerWidth : 0}px`}}>
                    <a  id='tmp-yt-link' href="https://www.youtube.com/@chrisbonham" target='_blank'>
                        <div className='video-container' style={{backgroundImage:`url(${youtube_link_thumb})`,backgroundSize:'contain', backgroundRepeat:'no-repeat', height:'0', paddingTop:'88%'}}>
                            
                            {/* <YouTubePlayer width='100%' height={height} embedId='s2a6lk3dC-k' /> */}
                        </div>
                    </a>
                    <div id='content-left-banner' style={w > 850? {width: `250px`} : {width: `250px`, margin: `auto`, boxShadow: `none`}}>
                        {w>850?
                            <div id='charity-container'>
                                {/* <h2 id='banner-title' className='header-font'>Charities:</h2> */}
                                <CharityTree margin={bannerMargin} isMobile={false}/>
                            </div>
                            :
                            <div id='charity-container-mobile'>
                                <CharityTree isMobile={true} />
                            </div>
                        }
                    </div>
                        <Blurb
                            odd={false}
                            id='about'
                            // color_1='#e4d86d'
                            // color_2='#c1b958'
                            color_1='#ffff'
                            color_2='#ffff'
                        >
                            <div 
                                className='blurb-col'
                                ref={anchors.about}
                            >
                                <h1>About Chris</h1>
                                <p>
                                Chris was born in San Francisco, CA on August 25, 1993. Upon graduating high school, he
                                moved to Chicago to follow in his father's footsteps and become a comedian, frequenting open
                                mics and Second City classes. He did not become a comedian. Instead, he spent the next nine
                                years as a butcher, charcutier and cook in Chicago establishments such as The Publican, Giant
                                and Publican Quality Meats where he would become the butcher supervisor. After 2-month stints
                                in both France and Italy staging in charcuterie and salumi shops, he returned to higher education
                                at Harry S. Truman community college. Chris currently lives in NYC where he is a noticeably
                                old undergraduate student, studying Biochemistry and Philosophy while frightening the
                                appropriately aged undergraduates at Columbia University. He spends his free time - Ok let's be
                                honest, I'm writing this - I spend my free time feeling superior to others for being a bay area
                                sports fan, going to farmer's markets, chipping away at the many restaurants marked on my
                                Google Maps, and taking swift and decisive Ls to chess hustlers in Washington Square Park.
                                </p>
                            </div>
                        </Blurb>
                    <Blurb
                        odd={true}
                        // color_1='#b13636'
                        // color_2='#801e1e'
                        color_1='#ffff'
                        color_2='#ffff'
                    >
                        <div className='blurb-col'>
                            <h1>About The Projects</h1>
                            <p>
                                Breaking Down, amongst the other work you will find here, aims to produce interconnective
                                ideas in humanities and sciences through familiar mediums such as food. The Donation Plan (to
                                your left if you are on the desktop website and above if you are on mobile) is in response to the
                                culture of internet content creation that exchanges viewer's free attention for monetary gain. The
                                exchange rate of attention to dollar heavily favors the creator and more specifically favors the
                                creator who commits to churning out endless content, resulting - in this humble and not at all
                                sanctimonious author's opinion - in the incentivization of plummeting quality. Treating the
                                public's attention as infinite, which is true en masse, ignores the reality that attention is
                                decidedly finite for each of the individuals who are spending it. The hope of the projects is to
                                have some inherent value within the content, justifying whatever amount of your finite attention
                                you choose to spend here. 50% of any earnings accrued from the projects will be donated to
                                environmental and educational organizations as an acknowledgement that viewership is
                                necessary for monetization to happen at all meaning that 50% of it should be returned to the
                                public in some manner. The hope is that the projects provide entertainment and food for thought
                                while raising money to support further thinking and keeping the planet a decent place to do so.
                                This all sounds good but if I change my mind and keep all the money, I will make a quiet
                                announcement on Twitter, thereby securing plausible deniability without slowing cashflow.
                            </p>
                        </div>
                    </Blurb>
                    <Blurb 
                        odd={false}
                        id='video'
                        // color_1='#65a221'
                        // color_2='#3e690f'
                        color_1='#ffff'
                        color_2='#ffff'
                    >
                        <div 
                            className='blurb-col'
                            ref={anchors.video}
                        >
                            <h1>Videos</h1>
                            <div style={{
                                fontSize: '80px',
                                fontWeight: '900',
                                margin: 'auto',
                                textAlign: 'center',
                            }}>COMING SOON...</div>
                            {/* <ThumbSlider 
                                rows={thumbDimensions.rows} 
                                cols={thumbDimensions.cols} 
                                compArr={videoThumbs} 
                                innerW={w} 
                                isLoading = {isLoading}
                            />     */}
                        </div>
                    </Blurb> 
                    <Blurb 
                        odd={true}
                        id='recipe'
                        // color_1='#65a221'
                        // color_2='#3e690f'
                        color_1='#ffff'
                        color_2='#ffff'
                    >
                        <div 
                            className='blurb-col'
                            ref={anchors.recipe}
                        >
                            <h1>Recipes</h1>
                            <ThumbSlider 
                                rows={thumbDimensions.rows} 
                                cols={thumbDimensions.cols} 
                                compArr={recipeThumbs} 
                                innerW={w} 
                                isLoading = {isBlogLoading}
                            />    
                        </div>
                    </Blurb> 
                    <Blurb 
                        odd={false}
                        id='blog'
                        // color_1='#65a221'
                        // color_2='#3e690f'
                        color_1='#ffff'
                        color_2='#ffff'
                    >
                        <div 
                            className='blurb-col'
                            ref={anchors.blog}
                        >
                            <h1>Blog Posts</h1>
                            <ThumbSlider 
                                rows={thumbDimensions.rows} 
                                cols={thumbDimensions.cols} 
                                compArr={blogThumbs} 
                                innerW={w} 
                                isLoading = {isBlogLoading}
                            />    
                        </div>
                    </Blurb>   
                    <div 
                        id='footer'
                        ref={anchors.contact}
                    >
                        <div className='footer-line'>
                            <div className='footer-line-title'>
                                <h3>Contact</h3>
                            </div>
                        </div>
                        <div className='footer-line'>
                            <div className='footer-line-content'>
                                <div className='seg-title'>
                                    Email
                                </div>
                                <div className='seg-body'>
                                    {socialLinkMaker(['email'])}
                                </div>
                            </div>
                            <div className='footer-line-content'>
                                <div className='seg-title'>
                                    DM me
                                </div>
                                <div className='seg-body'>
                                    {socialLinkMaker(['instagram', 'twitter'])}
                                </div>
                            </div>
                            <div className='footer-line-content'>
                                <div className='seg-title'>
                                    Donate
                                </div>
                                <div className='seg-body'>
                                    {socialLinkMaker(['patreon', 'paypal'])}
                                </div>
                            </div>
                        </div>
                        <div className='footer-line'>
                            <span> website created by <a href='http://jkrubin.github.io' target='_blank'>Josh Rubin</a> </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Homepage