import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faInstagram, faPatreon, faPaypal, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './index.css'
const socialLinks = {
    youtube:{
        url:"https://www.youtube.com/@chrisbonham",
        svg: faYoutube
    },
    twitter:{
        url: "https://twitter.com/ChrisBonham",
        svg: faTwitter
    },
    instagram:{
        url: "https://www.instagram.com/chrisbonhamcom/",
        svg: faInstagram
    },
    patreon:{
        url: "https://www.patreon.com/ChrisBonham",
        svg: faPatreon
    },
    paypal:{
        url: "https://www.paypal.com/paypalme/ChrisBonhamcom",
        svg: faPaypal
    },
    email:{
        url: "mailto: info@chrisbonham.com",
        svg: faEnvelope
    }
    
}

export const SingleLink = ({social}) =>{
    return(
        <a href={socialLinks[social].url} target='_blank'>
            <div className='social-link-container FA-icon'>
                <FontAwesomeIcon icon={ socialLinks[social].svg }/>
            </div>
        </a>
    )

}
